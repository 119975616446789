import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <title>Robert Mullins Info Site</title>
        <img src={logo} className="App-logo" alt="logo" />
        <p>
        Robert Mullins Info Site
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
          <a
              className="App-link"
              href="https://robertmullins.tech"
              target="_blank"
              rel="noopener noreferrer"
              >
              Go To My Current Website
      </a>
      </header>
    </div>
  );
}

export default App;
